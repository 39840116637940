import React from "react"
import {
  Heading,
  ImgCont,
  PointIcon,
  Points,
  PointText,
  PointUl,
  TextCont,
  WhyContainer,
  Wrapper,
} from "./styles/whyNow"
import { IoIosCheckmarkCircle } from "react-icons/io"
import { StaticImage } from "gatsby-plugin-image"
const data = [
  {
    id: 1,
    icon: <IoIosCheckmarkCircle />,
    point: "A surge in internet searches for healthcare information ",
  },
  {
    id: 2,
    icon: <IoIosCheckmarkCircle />,
    point: "Begin building a loyal customer base and online reviews ",
  },
  {
    id: 3,
    icon: <IoIosCheckmarkCircle />,
    point: "Patients are empowered and wants to be in control of their health",
  },
  {
    id: 4,
    icon: <IoIosCheckmarkCircle />,
    point: "Clinical and medication management will benefit from data insights",
  },
]
export default function MtmProvWhyNow() {
  return (
    <WhyContainer>
      <Wrapper>
        <ImgCont>
          <StaticImage height={600} imgStyle={{ borderRadius: '20px' }} src="https://res.cloudinary.com/tripleaim-software/image/upload/v1728636701/afyabook%20images/corp_1_afgdoq.png" alt="mtm" placeholder="blurred" />
        </ImgCont>
        <TextCont>
          <Heading>Why Now</Heading>
          <PointUl>
            {data.map(item => {
              const { id, icon, point } = item
              return (
                <Points key={id}>
                  <PointIcon>{icon}</PointIcon>
                  <PointText>{point}</PointText>
                </Points>
              )
            })}
          </PointUl>
        </TextCont>
      </Wrapper>
    </WhyContainer>
  )
}
