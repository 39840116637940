import React from "react"
import Seo from "../components/seo"
import MtmProvBanner from "../sections/provider-section/MtmProvBanner"
import MtmProvBenefits from "../sections/provider-section/MtmProvBenefits"
import MtmProvWhyNow from "../sections/provider-section/MtmProvWhyNow"
import WhyUs from "../sections/provider-section/WhyUs"
import MtmProvFaq from "../sections/faq/MtmFaqProv"
import Partners from "../components/partners/Partners"
import Footer from "../components/homeNav/footer/Footer"


export default function MtmProvider() {
  return (
    <>
    <Seo
        title="Medication Therapy Management Provider | Afyabook"
        description="Make a difference in your patients lives with the tools you need to provide medication therapy management and patient monitoring"
        img="https://www.afyabook.com/static/239eebef9f4502740a7ce3406960ad48/5e32c/providerHeroImg.webp"
        keywords="medication therapy management tools, medication therapy management kenya, pharmacist services, MTM"
        siteUrl="https://www.afyabook.com/medication-therapy-management-provider"
        canonical="https://www.afyabook.com/medication-therapy-management-provider"
      ></Seo>
      <MtmProvBanner />
      <MtmProvBenefits />
      <MtmProvWhyNow />
      <WhyUs />
      <MtmProvFaq />
      <Partners />
      <Footer />
    </>
  )
}
