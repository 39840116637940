import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "How do I join Afyabook?",
    body: "Send us an email to support@patameds.com and our representative will get in touch with on boarding details ",
  },
  {
    id: 1,
    title: "Do I pay anything to be on Afyabook?",
    body: "Listing on Afyabook is free though you may need to subscribe to access our back-office modules, analytics and receive online requests.",
  },
  {
    id: 3,
    title: "I have an e-commerce platform already; do I need to join Afyabook?",
    body: "Yes, in the internet world, getting traffic from a variety of sources gives you a competitive advantage",
  },
  {
    id: 4,
    title: "How do I get patients for MTM?",
    body: "Patients will book for your services through the platform. You will also get patients referred to you regionally.",
  },
  {
    id: 5,
    title: "Who qualifies to offer Medication Therapy Management services?",
    body: "To offer MTM, you will need to be licensed by The Pharmacy and Poisons Board Kenya as a provider of pharmaceutical professional services or is licensed under community or hospital pharmacy.",
  },
  {
    id: 6,
    title: "Where will I document my MTM services?",
    body: "Afyabook will offer workflow tools to assist you with documentation, patient interaction, and collaboration with other service providers.",
  },
  {
    id: 7,
    title: "What’s so special about Afyabook?",
    body: "It was developed with a Systems Thinking approach to produce a data-intensive collaborative platform based on social media concepts and powered by Machine Learning. Parties can exchange data, have a common perspective of product and service status, and collaborate technically once the links are formed.",
  },
]
export default function MtmProvFaq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions</h1>
          <h3 className="subheading">Some frequent questions clients ask</h3>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
