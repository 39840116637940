import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios"
import {
  BannerBtn,
  BannerContainer,
  BannerWrapper,
  Heading,
  ImgCont,
  ScrollerContainer,
  ScrollText,
  ScrollWheel,
  Subtitle,
  TextContainer,
} from "../service-section/styles/bannerElements"
import {
  HeaderWrapper,
  ModalHeader,
  StyledButton,
  StyledForm,
  StyledIcon,
  StyledInput,
  SuccessContainer,
  SuccessHeader,
  SuccessImg,
  SuccessSubtitle,
  SuccessText,
} from "../../components/providerSection/providerElements"
import HeroThank from "../service-section/images/thankyou.svg"
import useAuthState from "../../stores/auth"

export default function MtmProvBanner() {
  const [show, setShow] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  //success modal state
  const close = () => setModalOpen(false)
  const open = () => setModalOpen(true)

  const handleClose = () => {
    setShow(false)
    setSuccess("")
    setError("")
  }
  const handleShow = () => setShow(true)

  const { user } = useAuthState()

  const [name, setName] = useState(
    user?.firstName
      ? user.firstName
      : "" + "" + user?.lastName
      ? user.lastName
      : ""
  )
  const [email, setEmail] = useState(user?.email || "")

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const uploadData = async () => {
    try {
      setError("")
      setSuccess("")
      if (!name || !email) {
        setError("Missing required fields")
      } else {
        setLoading(true)

        const data = {
          name,
          email,
          type: "providerMtm",
          //change type for each page
        }
        await axios.post(`${process.env.GATSBY_API_URL}/registrations/`, {
          ...data,
        })
        //uncomment later on

        setSuccess("Success")
        setLoading(false)
        setSubmitted(true)
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error",
        err
      )
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  return (
    <>
      <BannerContainer>
        <BannerWrapper>
          <TextContainer>
            <Heading>Make a difference in your patients lives</Heading>
            <Subtitle>
              Providing pharmacies with the tools they need to provide
              medication therapy management and patient monitoring
            </Subtitle>
            <BannerBtn onClick={handleShow}>Get started</BannerBtn>
            <Modal
              show={show}
              onHide={handleShow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyledForm>
                {success && (
                  <>
                    <SuccessContainer>
                      <StyledIcon onClick={handleClose} />
                      <SuccessImg src={HeroThank} />
                      <SuccessHeader>Welcome onBoard, {name}</SuccessHeader>
                      <SuccessText>Thank you for joining</SuccessText>
                      <SuccessSubtitle>
                        Afyabook Medication Therapy Management as a Provider
                      </SuccessSubtitle>
                    </SuccessContainer>
                  </>
                )}
                {!success && (
                  <>
                    <StyledIcon onClick={handleClose} />
                    <p style={{ color: "red" }}>{error}</p>
                    <HeaderWrapper>
                      <ModalHeader>
                        Make a difference in your patients lives
                      </ModalHeader>
                      <Subtitle>
                        Providing pharmacies with the tools they need to provide
                        medication therapy management and patient monitoring
                      </Subtitle>
                    </HeaderWrapper>
                    <label htmlFor="name">Name</label>
                    <StyledInput
                      type="text"
                      name="name"
                      value={name}
                      onChange={e => {
                        setName(e.target.value)
                      }}
                    />
                    <label htmlFor="email">Email</label>
                    <StyledInput
                      type="email"
                      name="email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                    />
                    {loading && (
                      <p style={{ color: "green", fontSize: "1.2rem" }}>
                        Loading...
                      </p>
                    )}
                    <StyledButton
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      disabled={submitted || loading || !name || !email}
                      onClick={() => {
                        uploadData() && (modalOpen ? close() : open())
                      }}
                    >
                      Send Message
                    </StyledButton>{" "}
                  </>
                )}
              </StyledForm>
            </Modal>
          </TextContainer>
          <ImgCont>
            <StaticImage
              src="https://res.cloudinary.com/tripleaim-software/image/upload/v1728634949/afyabook%20images/mtm_1_s3jhe7.png"
              alt="Medication Therapy Management Provider"
              placeholder="blurred"
              imgStyle={{ borderRadius: '20px' }}
            />
          </ImgCont>
        </BannerWrapper>
        <ScrollerContainer>
          <ScrollWheel></ScrollWheel>
        </ScrollerContainer>
      </BannerContainer>
      <ScrollText>Scroll down to learn more</ScrollText>
    </>
  )
}
